import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Box,
  Input,
  List,
  ListItem,
  Text,
  VStack,
  Card,
  CardBody,
  Divider,
} from "@chakra-ui/react";
import { getSurfBreaks } from "../services/surfbreaks";
import { SurfBreak } from "../types/surfbreak";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidGFyc2llciIsImEiOiJjbTIxMjJraHkwZDlhMmtwemZvcTgxM3htIn0.HLbTp0stX-aUx3Mw-g2dfQ";

interface SurfBreakSelectorProps {
  onSelect: (surfBreak: SurfBreak) => void;
}

const CustomMarker: React.FC<{
  onClick: () => void;
  label: string;
  showLabel: boolean;
  isSelected: boolean;
}> = ({ onClick, label, showLabel, isSelected }) => (
  <div style={{ position: "relative", cursor: "pointer" }} onClick={onClick}>
    <svg width="30" height="30" viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="12" fill={isSelected ? "blue" : "black"} />
      <path
        d="M7 18 C 10 10, 13 18, 16 14 C 19 10, 22 18, 25 14"
        stroke="white"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
    {showLabel && (
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          whiteSpace: "nowrap",
          fontSize: "12px",
          fontWeight: "bold",
          textShadow: "1px 1px 1px rgba(255,255,255,0.5)",
          marginTop: "2px",
          color: isSelected ? "blue" : "black",
        }}
      >
        {label}
      </div>
    )}
  </div>
);

const SurfBreakSelector: React.FC<SurfBreakSelectorProps> = ({ onSelect }) => {
  const [surfBreaks, setSurfBreaks] = useState<SurfBreak[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 5,
  });
  const [selectedBreak, setSelectedBreak] = useState<SurfBreak | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const expandedContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleResize = () => {
      checkMobile();
      setKeyboardHeight(window.innerHeight * 0.4);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    const fetchSurfBreaks = async () => {
      try {
        const data = await getSurfBreaks();
        if (Array.isArray(data)) {
          setSurfBreaks(data);
          if (data.length > 0) {
            fitMapToBreaks(data);
          }
        } else {
          throw new Error("Invalid data format received");
        }
      } catch (err: any) {
        setError("Failed to load surf spots.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSurfBreaks();
  }, []);

  const fitMapToBreaks = useCallback((breaks: SurfBreak[]) => {
    const lngs = breaks.map((b) => Number(b.longitude));
    const lats = breaks.map((b) => Number(b.latitude));
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);

    setViewport({
      latitude: (minLat + maxLat) / 2,
      longitude: (minLng + maxLng) / 2,
      zoom: 5,
    });
  }, []);

  const filteredBreaks = useMemo(() => {
    if (!Array.isArray(surfBreaks)) {
      console.error("surfBreaks is not an array:", surfBreaks);
      return [];
    }
    return surfBreaks.filter((breakItem) =>
      breakItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [surfBreaks, searchTerm]);

  const handleBreakSelect = useCallback(
    (surfBreak: SurfBreak) => {
      setSelectedBreak(surfBreak);
      setSearchTerm(surfBreak.name);
      setViewport({
        latitude: Number(surfBreak.latitude),
        longitude: Number(surfBreak.longitude),
        zoom: 10,
      });
      onSelect(surfBreak);
      setIsExpanded(false);
    },
    [onSelect]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchFocus = () => {
    setIsExpanded(true);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isMobile &&
        isExpanded &&
        cardRef.current &&
        expandedContentRef.current &&
        !cardRef.current.contains(event.target as Node) &&
        !expandedContentRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpanded, isMobile]);

  const renderExpandedContent = () => (
    <Box
      position={isMobile ? "fixed" : "absolute"}
      top={isMobile ? 0 : "100%"}
      left={isMobile ? 0 : 0}
      right={isMobile ? 0 : "auto"}
      bottom={isMobile ? keyboardHeight : "auto"}
      width={isMobile ? "100%" : "100%"}
      height={isMobile ? `calc(100% - ${keyboardHeight}px)` : "auto"}
      maxHeight={isMobile ? "none" : "80vh"}
      zIndex={1000}
      bg="white"
      boxShadow="lg"
      borderRadius={isMobile ? "none" : "md"}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      ref={expandedContentRef}
    >
      {isMobile && (
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Input
            ref={inputRef}
            placeholder="Surf Spot"
            value={searchTerm}
            onChange={handleSearchChange}
            flex={1}
            mr={2}
            autoFocus
          />
          <Text as="button" onClick={handleClose} fontWeight="bold">
            Close
          </Text>
        </Box>
      )}
      <Box flex={1} minHeight={0} display="flex" flexDirection="column">
        {filteredBreaks.length > 0 && (
          <Box
            flex="0 0 auto"
            maxHeight={isMobile ? "40%" : "150px"}
            overflowY="auto"
          >
            <List spacing={2}>
              {filteredBreaks.map((breakItem, index) => (
                <React.Fragment key={breakItem.id}>
                  {index > 0 && <Divider />}
                  <ListItem
                    onClick={() => handleBreakSelect(breakItem)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                    p={2}
                    borderRadius="md"
                  >
                    <Text fontWeight="medium">{breakItem.name}</Text>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}
        <Box flex={isMobile ? 1 : "0 0 300px"} minHeight={0}>
          <Map
            {...viewport}
            onMove={(evt) => setViewport(evt.viewState)}
            style={{ width: "100%", height: "100%" }}
            mapStyle="mapbox://styles/tarsier/cm213dmtj004y01r56bovhrse"
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          >
            {surfBreaks.map((breakItem) => (
              <Marker
                key={breakItem.id}
                latitude={Number(breakItem.latitude)}
                longitude={Number(breakItem.longitude)}
              >
                <CustomMarker
                  onClick={() => handleBreakSelect(breakItem)}
                  label={breakItem.name}
                  showLabel={viewport.zoom > 8}
                  isSelected={selectedBreak?.id === breakItem.id}
                />
              </Marker>
            ))}
          </Map>
        </Box>
      </Box>
    </Box>
  );

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box position="relative" width="100%">
      <Box width="100%" height="auto" visibility="hidden">
        <Card width="100%" maxWidth="500px">
          <CardBody>
            <Input placeholder="Placeholder" />
          </CardBody>
        </Card>
      </Box>

      <Card
        ref={cardRef}
        width="100%"
        maxWidth="500px"
        boxShadow={isExpanded ? "lg" : "none"}
        transition="all 0.3s"
        position="absolute"
        top="0"
        left="0"
        zIndex={isExpanded ? 1000 : "auto"}
      >
        <CardBody>
          <Input
            placeholder="Surf Spot"
            value={searchTerm}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            bg="white"
          />
        </CardBody>
      </Card>
      {isExpanded && isMobile && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={999}
          bg="rgba(0,0,0,0.5)"
          onClick={handleClose}
        />
      )}
      {isExpanded &&
        (isMobile
          ? ReactDOM.createPortal(renderExpandedContent(), document.body)
          : renderExpandedContent())}
    </Box>
  );
};

export default SurfBreakSelector;
