import React from "react";
import { Box, Heading, HStack, Text, VStack, Image } from "@chakra-ui/react";
import { Album } from "../types/album";

interface AlbumCardProps {
  album: Album;
  onClick: () => void;
}

const AlbumCard: React.FC<AlbumCardProps> = ({ album, onClick }) => {
  return (
    <Box
      borderWidth="2px"
      borderColor={"accent.500"}
      borderRadius="lg"
      p={4}
      cursor="pointer"
      bg={"white"}
      dropShadow={"md"}
      onClick={onClick}
      _hover={{ boxShadow: "md" }}
    >
      <VStack align="start" spacing={2}>
        <Heading size="md">{album.surf_break.name}</Heading>
        <Text>Day: {album.start_datetime.toLocaleDateString()}</Text>
        <Text>
          Time:{" "}
          {`${album.start_datetime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })} - ${album.end_datetime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}`}
        </Text>
        <Text>Files: {album.media.length}</Text>
        <HStack spacing={2} mt={2}>
          {album.media.slice(0, 3).map((item, index) => (
            <Image
              key={index}
              src={
                item.media_type === "photo"
                  ? item.watermarked_file
                  : item.thumbnail
              }
              alt={`Media ${index + 1}`}
              boxSize="50px"
              objectFit="cover"
              borderRadius="md"
            />
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

export default AlbumCard;
