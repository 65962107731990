import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  VStack,
  HStack,
  Card,
  CardBody,
  Input,
} from '@chakra-ui/react';

interface TimeRangeSelectorProps {
  onSelect: (startTime: string, endTime: string) => void;
}

const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({ onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [rangeValues, setRangeValues] = useState([5, 21]); // Default to 05:00 to 21:00
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Call onSelect with default values when component mounts
    onSelect(hoursToTime(rangeValues[0]), hoursToTime(rangeValues[1]));
  }, []);

  const hoursToTime = (hours: number) => {
    return hours.toString().padStart(2, '0') + ':00';
  };

  const formatDisplayTime = (hours: number) => {
    const period = hours < 12 ? 'AM' : 'PM';
    const displayHours = hours % 12 || 12;
    return `${displayHours}:00 ${period}`;
  };

  const handleRangeChange = (newValues: number[]) => {
    setRangeValues(newValues);
    onSelect(hoursToTime(newValues[0]), hoursToTime(newValues[1]));
  };

  return (
    <Box position="relative" width="100%">
      <Box width="100%" height="auto" visibility="hidden">
        <Card width="100%" maxWidth="500px">
          <CardBody>
            <Input placeholder="Placeholder" />
          </CardBody>
        </Card>
      </Box>

      <Card
        ref={cardRef}
        width="100%"
        maxWidth="500px"
        boxShadow={isExpanded ? "lg" : "none"}
        transition="all 0.3s"
        position="absolute"
        top="0"
        left="0"
        zIndex={isExpanded ? 1000 : "auto"}
      >
        <CardBody>
          <VStack spacing={4} align="stretch">
            <Input
              placeholder="Select time range..."
              value={`${formatDisplayTime(rangeValues[0])} - ${formatDisplayTime(rangeValues[1])}`}
              onFocus={() => setIsExpanded(true)}
              readOnly
            />
            {isExpanded && (
              <Box pt={4}>
                <RangeSlider
                  aria-label={['start time', 'end time']}
                  value={rangeValues}
                  min={0}
                  max={24}
                  step={1}
                  onChange={handleRangeChange}
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>
                <HStack justify="space-between" mt={2}>
                  <Text fontSize="sm">{formatDisplayTime(rangeValues[0])}</Text>
                  <Text fontSize="sm">{formatDisplayTime(rangeValues[1])}</Text>
                </HStack>
              </Box>
            )}
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default TimeRangeSelector;