import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Card,
  CardBody,
  Input,
  VStack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { css } from '@emotion/react';

interface DateSelectorProps {
  onSelect: (date: Date | null) => void;
}

const datePickerStyles = css`
  .custom-calendar {
    font-family: inherit;
    border: none;
    width: 100%;
    max-width: 350px;
  }

  .custom-calendar .react-datepicker__month-container {
    width: 100%;
  }

  .custom-calendar .react-datepicker__header {
    background-color: #f7fafc;
    border-bottom: none;
  }

  .custom-calendar .react-datepicker__day-name,
  .custom-calendar .react-datepicker__day {
    width: 14.28%; /* 100% / 7 days */
    line-height: 2.5rem;
    margin: 0;
  }

  .custom-calendar .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .custom-calendar .react-datepicker__day--selected,
  .custom-calendar .react-datepicker__day--keyboard-selected {
    background-color: #3182ce;
    color: white;
  }

  .custom-calendar .react-datepicker__day:hover {
    background-color: #edf2f7;
  }

  .custom-calendar .react-datepicker__day--disabled {
    color: #cbd5e0;
    cursor: not-allowed;
  }

  .custom-calendar .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }
`;

const DateSelector: React.FC<DateSelectorProps> = ({ onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Call onSelect with default value when component mounts
    onSelect(selectedDate);
  }, []);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    onSelect(date);
  };

  return (
    <Box position="relative" width="100%" css={datePickerStyles}>
      <Box width="100%" height="auto" visibility="hidden">
        <Card width="100%" maxWidth="500px">
          <CardBody>
            <Input placeholder="Placeholder" />
          </CardBody>
        </Card>
      </Box>

      <Card
        ref={cardRef}
        width="100%"
        maxWidth="500px"
        boxShadow={isExpanded ? "lg" : "none"}
        transition="all 0.3s"
        position="absolute"
        top="0"
        left="0"
        zIndex={isExpanded ? 1000 : "auto"}
      >
        <CardBody>
          <VStack spacing={4} align="stretch">
            <Input
              placeholder="Select date..."
              value={selectedDate ? selectedDate.toLocaleDateString() : ''}
              onFocus={() => setIsExpanded(true)}
              readOnly
            />
            {isExpanded && (
              <Box pt={4} display="flex" justifyContent="center">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  inline
                  calendarClassName="custom-calendar"
                  maxDate={new Date()} // Disable future dates
                  shouldCloseOnSelect={false} // Keep the calendar open after selection
                />
              </Box>
            )}
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default DateSelector;