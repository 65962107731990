import axios from 'axios';
import api from "./api";

interface LoginResponse {
  access: string;
  refresh: string;
}

interface SignupData {
  username: string;
  email: string;
  password: string;
  is_photographer: boolean;
  is_surfer: boolean;
  first_name: string;
  last_name: string;
  bio: string;
  location: string;
}

const noauth_api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000',
});

export const loginUser = async (email: string, password: string): Promise<LoginResponse> => {
  const response = await noauth_api.post('/api/token/', { email, password });
  return response.data;
};


export const signupUser = async (data: SignupData) => {
  const response = await noauth_api.post('/api/register/', data);
  return response.data;
};

export const verifyEmail = async (uidb64: string, token: string) => {
  const response = await noauth_api.get(`/api/verify-email/${uidb64}/${token}/`);
  return response.data;
};

export const resendVerificationEmail = async () => {
  try {
    const response = await api.post('/api/resend-verification-email/');
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const requestPasswordReset = async (email: string) => {
  const response = await noauth_api.post('/api/password-reset/', { email });
  return response.data;
};

export const resetPassword = async (uidb64: string, token: string, password: string) => {
  const response = await noauth_api.post(`/api/password-reset-confirm/${uidb64}/${token}/`, { password });
  return response.data;
};