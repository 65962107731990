// SurferLandingPage.tsx
import React, { useRef } from "react";
import {
  Box,
  Heading,
  VStack,
  Spacer,
  Link,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import MediaSearchFilter, {
  FilterValues,
} from "../../components/MediaSearchFilter";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const demoImages = [
  "/surfer-1.png",
  "/surfer-2.png",
  "/surfer-3.png",
  "/surfer-4.png",
];

const SurferLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const isInView = useInView(gridRef, {
    once: true,
    margin: "-100px",
  });

  // Determine number of images based on screen size
  const columns = useBreakpointValue({ base: 2, md: 3 }) ?? 2;
  const visibleImages = demoImages.slice(0, columns);

  const handleFilter = (filters: FilterValues) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });
    navigate(`/media?${searchParams.toString()}`);
  };

  return (
    <VStack
      spacing={10}
      align="center"
      width="100%"
      pt={{ base: "10px", md: "10vh" }}
    >
      <Box width="100%">
        <VStack spacing={4} align="stretch">
          <Heading as="h5" size="lg" color="black">
            Find your surf photos and videos.
          </Heading>
          <MediaSearchFilter onFilter={handleFilter} />

          {/* Demo Media Grid */}
          <Box position="relative" mt={8} ref={gridRef}>
            <SimpleGrid columns={[2, 2, 3]} spacing={4} width="100%">
              {visibleImages.map((image, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: isInView ? 1 : 0,
                    y: isInView ? 0 : 20,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.2,
                    ease: "easeOut",
                  }}
                >
                  <Box
                    position="relative"
                    height="0"
                    paddingBottom="100%"
                    overflow="hidden"
                  >
                    <Box
                      as="img"
                      src={image}
                      position="absolute"
                      top="0"
                      left="0"
                      width="100%"
                      height="100%"
                      objectFit="cover"
                      transition="transform 0.3s ease"
                    />
                  </Box>
                </motion.div>
              ))}
            </SimpleGrid>

            {/* Gradient Overlay */}
            <Box
              position="absolute"
              bottom="-20px"
              left="0"
              right="0"
              height="170px"
              background="linear-gradient(to bottom, transparent, #FFFEF0)"
              pointerEvents="none"
            />
          </Box>

          <Spacer />
        </VStack>
      </Box>
    </VStack>
  );
};

export default SurferLandingPage;
