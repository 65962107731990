import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Container,
  Divider,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  const [currentDate] = useState(new Date().toLocaleDateString());

  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading as="h1" size="2xl" mb={4}>Swelter Privacy Policy</Heading>
          <Text>Last Updated: {currentDate}</Text>
        </Box>

        <Text>
          This Privacy Policy describes how Tarsier Technologies LLC ("we", "our", or "us") collects, uses, and discloses your personal information when you use our Swelter website, mobile application, and services (collectively, the "Service").
        </Text>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>1. Information We Collect</Heading>
          <Text mb={2}>1.1. Personal Information:</Text>
          <List spacing={2} ml={5}>
            <ListItem>Name</ListItem>
            <ListItem>Email address</ListItem>
            <ListItem>Phone number</ListItem>
            <ListItem>Postal address</ListItem>
            <ListItem>Payment information</ListItem>
            <ListItem>Date of birth</ListItem>
            <ListItem>Profile picture</ListItem>
            <ListItem>Social media handles (if provided)</ListItem>
          </List>
          <Text mt={4} mb={2}>1.2. User Content:</Text>
          <List spacing={2} ml={5}>
            <ListItem>Photos and videos uploaded to the platform</ListItem>
            <ListItem>Comments and descriptions associated with uploads</ListItem>
            <ListItem>Messages sent through our platform</ListItem>
          </List>
          <Text mt={4} mb={2}>1.3. Usage Data:</Text>
          <List spacing={2} ml={5}>
            <ListItem>IP address</ListItem>
            <ListItem>Browser type and version</ListItem>
            <ListItem>Operating system</ListItem>
            <ListItem>Pages visited and order of visit</ListItem>
            <ListItem>Time and date of visits</ListItem>
            <ListItem>Time spent on pages</ListItem>
            <ListItem>Unique device identifiers</ListItem>
            <ListItem>Other diagnostic data</ListItem>
          </List>
          <Text mt={4} mb={2}>1.4. Location Data:</Text>
          <List spacing={2} ml={5}>
            <ListItem>Geolocation data (if permitted by you)</ListItem>
            <ListItem>Information about the location of uploaded images (if available in metadata)</ListItem>
          </List>
          <Text mt={4} mb={2}>1.5. Cookies and Tracking Technologies:</Text>
          <List spacing={2} ml={5}>
            <ListItem>Cookies</ListItem>
            <ListItem>Web beacons</ListItem>
            <ListItem>Pixels</ListItem>
            <ListItem>Device identifiers</ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>2. How We Collect Information</Heading>
          <Text mb={2}>2.1. Information you provide to us directly when you:</Text>
          <List spacing={2} ml={5}>
            <ListItem>Register for an account</ListItem>
            <ListItem>Upload content</ListItem>
            <ListItem>Make a purchase</ListItem>
            <ListItem>Contact our customer support</ListItem>
            <ListItem>Participate in surveys or promotions</ListItem>
            <ListItem>Communicate with other users</ListItem>
          </List>
          <Text mt={4}>2.2. Information collected automatically when you use the Service.</Text>
          <Text mt={4}>2.3. Information from third parties, such as social media platforms, if you choose to link your Swelter account to these platforms.</Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>3. How We Use Your Information</Heading>
          <Text mb={2}>We use your information to:</Text>
          <List spacing={2} ml={5}>
            <ListItem>3.1. Provide, maintain, and improve our Service</ListItem>
            <ListItem>3.2. Process transactions and send transaction notifications</ListItem>
            <ListItem>3.3. Communicate with you about your account, our Service, and promotional offers</ListItem>
            <ListItem>3.4. Personalize your experience and deliver targeted content and advertising</ListItem>
            <ListItem>3.5. Conduct marketing activities and analytics</ListItem>
            <ListItem>3.6. Train machine learning models to improve our platform and user experience</ListItem>
            <ListItem>3.7. Detect, prevent, and address technical issues, fraud, or other illegal activities</ListItem>
            <ListItem>3.8. Comply with legal obligations</ListItem>
            <ListItem>3.9. Enforce our Terms of Service</ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>4. Information Sharing and Disclosure</Heading>
          <Text mb={2}>We may share your information:</Text>
          <List spacing={2} ml={5}>
            <ListItem>4.1. With other users as part of the normal operation of the Service (e.g., displaying photographer information with their photos)</ListItem>
            <ListItem>4.2. With service providers, contractors, and agents who perform services for us</ListItem>
            <ListItem>4.3. With advertising partners to show you relevant ads on and off the Service</ListItem>
            <ListItem>4.4. In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition</ListItem>
            <ListItem>4.5. When required by law or to protect our rights, privacy, safety, or property, or that of our users or others</ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>5. Data Retention</Heading>
          <Text>
            We retain your personal information for as long as necessary to provide you with the Service and fulfill the purposes outlined in this Privacy Policy. We may also retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>6. Data Security</Heading>
          <Text>
            We implement reasonable security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>7. Your Rights and Choices</Heading>
          <Text mb={2}>Depending on your location, you may have the right to:</Text>
          <List spacing={2} ml={5}>
            <ListItem>7.1. Access the personal information we hold about you</ListItem>
            <ListItem>7.2. Correct inaccurate information</ListItem>
            <ListItem>7.3. Delete your personal information</ListItem>
            <ListItem>7.4. Object to or restrict certain processing of your information</ListItem>
            <ListItem>7.5. Data portability</ListItem>
            <ListItem>7.6. Withdraw consent at any time, where we rely on consent to process your personal information</ListItem>
          </List>
          <Text mt={4}>
            To exercise these rights, please contact us using the information provided at the end of this policy.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>8. Children's Privacy</Heading>
          <Text>
            Swelter is not intended for use by children under 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>9. International Data Transfers</Heading>
          <Text>
            Your information may be transferred to and processed in countries other than your own. These countries may have different data protection laws than your country of residence. When we transfer your information to other countries, we will protect that information as described in this Privacy Policy and in accordance with applicable law.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>10. Cookies and Similar Technologies</Heading>
          <Text>
            We use cookies and similar tracking technologies to collect and use personal information about you, including to serve interest-based advertising. For more information about our use of these technologies and how to control them, please see our Cookie Policy.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>11. Third-Party Links and Services</Heading>
          <Text>
            Our Service may contain links to third-party websites, services, or advertisements. We are not responsible for the privacy practices or content of these third parties. We encourage you to read the privacy policies of these third parties.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>12. Changes to This Privacy Policy</Heading>
          <Text>
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a notice on our website or by sending you an email. Your continued use of the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>13. California Privacy Rights</Heading>
          <Text>
            If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA). For more information about these rights and how to exercise them, please see our California Privacy Notice.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>14. Do Not Track Signals</Heading>
          <Text>
            We do not currently respond to "Do Not Track" signals or other similar mechanisms.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>15. Contact Us</Heading>
          <Text mb={2}>If you have any questions about this Privacy Policy, please contact us at:</Text>
          <Text>
            Tarsier Technologies LLC<br />
            contact@tarsiertech.io<br />
            (424) 744-3368
          </Text>
          <Text mt={4}>Data Protection Officer: Sean Geiger (contact@tarsiertech.io)</Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default PrivacyPolicy;