import React, {useState} from 'react';
import {Box, Text, Button, useToast, Flex, Heading} from '@chakra-ui/react';
import { getStripeAccountLink } from '../../services/stripe';

const CompleteProfilePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleCompleteProfile = async () => {
    setIsLoading(true);
    try {
      const { url } = await getStripeAccountLink();
      window.location.href = url;
    } catch (error) {
      const errorMessage = 'Unable to connect to Stripe. Please try again.';
      toast({
        title: 'Connection Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex minH="80vh" align="center" justify="center">
      <Box
        bg="white"
        p={8}
        rounded="xl"
        borderWidth="2px"
        borderColor="accent.600"
        shadow="lg"
        w={{ base: '90%', md: '500px' }}
      >
        <Heading mb={6} textAlign="center">
          Set Up Payments
        </Heading>

        <Text fontSize="md" mb={6} textAlign="center">
          Connect your Stripe account to receive payments from your sales.
          This only takes a few minutes to complete.
        </Text>

        <Button
          width="full"
          onClick={handleCompleteProfile}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Connect Stripe Account
        </Button>
      </Box>
    </Flex>
  );
};

export default CompleteProfilePage;