import React, { useState } from "react";
import {
  Spinner,
  SimpleGrid,
  Image,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  AspectRatio,
  IconButton,
  Icon,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  VStack,
  HStack,
  Link,
  Badge,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  InfoIcon,
} from "@chakra-ui/icons";
import { FaPlay } from "react-icons/fa";
import { Media } from "../types/media";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { createCheckoutSession } from "../services/stripe";
import { deleteMedia, updateMediaPrice } from "../services/media";
import { Link as RouterLink } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51Q79NIHHtTz6QQwBQOLrbbDeijaM2PoJLBc25lGNAtCuMP3hh8N6i5VPeb7yjnkh19HbD9Ao3kJCX1x6yK9P6mpY00EFcx8Uog"
);

interface MediaGridProps {
  media: Media[];
  isOwner?: boolean;
  onMediaUpdate: (updatedMedia: Media) => void;
}

const MediaGrid: React.FC<MediaGridProps> = ({
  media,
  isOwner = false,
  onMediaUpdate,
}) => {
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(
    null
  );
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [newPrice, setNewPrice] = useState<number>(0);

  const toast = useToast();

  const handleMediaClick = (index: number) => {
    if (!media[index].is_processed) {
      return;
    }
    setSelectedMediaIndex(index);
    setClientSecret(null);
    setIsEditingPrice(false);
    if (isOwner && index !== null) {
      setNewPrice(Math.max(1, media[index].price / 100));
    }
  };

  const handleCloseModal = () => {
    setSelectedMediaIndex(null);
    setClientSecret(null);
    setIsEditingPrice(false);
  };

  const handleNavigate = (direction: "prev" | "next") => {
    if (selectedMediaIndex === null) return;
    const newIndex =
      direction === "prev"
        ? (selectedMediaIndex - 1 + media.length) % media.length
        : (selectedMediaIndex + 1) % media.length;
    setSelectedMediaIndex(newIndex);
    setClientSecret(null);
    setIsEditingPrice(false);
    setNewPrice(Math.max(1, media[newIndex].price / 100));
  };

  const handleBuyClick = async () => {
    if (selectedMediaIndex === null) return;
    try {
      const response = await createCheckoutSession(
        media[selectedMediaIndex].id
      );
      setClientSecret(response.client_secret);
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast({
        title: "Error creating checkout session",
        description: "Unable to process the purchase. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMedia = async (mediaId: string) => {
    try {
      await deleteMedia(mediaId);
      toast({
        title: "Media deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting media",
        description: "Unable to delete media. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = () => {
    if (selectedMediaIndex === null) return;
    const mediaId = media[selectedMediaIndex].id;
    const confirmed = window.confirm(
      "Are you sure you want to delete this media?"
    );
    if (confirmed) {
      handleDeleteMedia(mediaId);
      handleCloseModal();
    }
  };

  const handleEditPriceClick = () => {
    setIsEditingPrice(true);
  };

  const handlePriceChange = (value: string) => {
    setNewPrice(Math.max(1, parseFloat(value)));
  };

  const handleSavePrice = async () => {
    if (selectedMediaIndex === null) return;
    const mediaId = media[selectedMediaIndex].id;
    try {
      const updatedMedia = await updateMediaPrice(mediaId, newPrice * 100);
      onMediaUpdate(updatedMedia);
      setIsEditingPrice(false);
      toast({
        title: "Price updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating price",
        description: "Unable to update price. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderMediaPreview = (item: Media) => {
    if (!item.is_processed) {
      return (
        <AspectRatio ratio={1}>
          <Flex align="center" justify="center" bg="gray.100">
            <Spinner size="xl" />
          </Flex>
        </AspectRatio>
      );
    } else {
      return (
        <AspectRatio ratio={1}>
          <Box position="relative">
            <Image
              src={
                item.media_type === "photo"
                  ? item.watermarked_file
                  : item.thumbnail
              }
              alt={"Surf media"}
              objectFit="cover"
            />
            {item.media_type === "video" && (
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaPlay} boxSize={12} color="white" />
              </Box>
            )}
          </Box>
        </AspectRatio>
      );
    }
  };

  const renderSelectedMedia = (item: Media) => {
    if (item.media_type === "photo") {
      return (
        <Image
          src={item.watermarked_file}
          alt="Enlarged surf photo"
          maxHeight="70vh"
          maxWidth="100%"
          objectFit="contain"
          margin="0 auto"
        />
      );
    } else {
      return (
        <Box width="100%" display="flex" justifyContent="center">
          <video
            controls
            autoPlay
            style={{
              maxHeight: "70vh",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          >
            <source src={item.watermarked_file} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    }
  };

  if (!media || !Array.isArray(media)) {
    return (
      <Box textAlign="center" p={4}>
        <Text>No media found</Text>
      </Box>
    );
  }

  return (
    <>
      {isOwner && (
        <Flex
          align="center"
          bg="blue.50"
          p={2}
          borderRadius="md"
          mb={4}
          maxW="fit-content"
        >
          <Icon as={InfoIcon} color="blue.400" mr={2} />
          <Text fontSize="sm" color="blue.600">
            Click on individual photo or video to adjust pricing
          </Text>
        </Flex>
      )}
      <SimpleGrid
        columns={[1, 3, 4]}
        spacing={2}
        bg="white"
        borderRadius="xl"
        p={4}
        shadow="lg"
      >
        {media.map((item, index) => (
          <Box
            key={item.id}
            cursor={item.is_processed ? "pointer" : "default"}
            onClick={() => handleMediaClick(index)}
            position="relative"
          >
            {item.is_purchased && isOwner && (
              <Badge
                position="absolute"
                top={2}
                right={2}
                colorScheme="green"
                zIndex={1}
              >
                Purchased
              </Badge>
            )}
            {renderMediaPreview(item)}
            {!item.is_processed && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="blackAlpha.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="white" fontWeight="bold">
                  Processing
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </SimpleGrid>

      <Modal
        isOpen={selectedMediaIndex !== null}
        onClose={handleCloseModal}
        size={clientSecret ? "xl" : "4xl"}
        isCentered
      >
        <ModalOverlay backdropFilter="blur(8px)" />
        <ModalContent
          bg="gray.900"
          borderRadius="lg"
          overflow="hidden"
          maxWidth={clientSecret ? "500px" : { base: "95vw", lg: "1200px" }}
          maxHeight={clientSecret ? "800px" : "none"}
          shadow="2xl"
        >
          <ModalCloseButton
            color="white"
            bg="transparent"
            _hover={{ bg: "whiteAlpha.200" }}
            borderRadius="full"
            top={4}
            right={4}
            zIndex={3}
          />
          <ModalBody p={0}>
            {clientSecret ? (
              <Box height="full" minH="500px">
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{ clientSecret }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </Box>
            ) : (
              selectedMediaIndex !== null && (
                <VStack spacing={0} align="stretch">
                  <Box
                    position="relative"
                    bg="black"
                    minHeight="400px"
                    display="flex"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="Previous"
                      icon={<ChevronLeftIcon boxSize={6} />}
                      onClick={() => handleNavigate("prev")}
                      position="absolute"
                      left={4}
                      top="50%"
                      transform="translateY(-50%)"
                      variant="ghost"
                      color="white"
                      size="sm"
                      borderRadius="full"
                      bg="blackAlpha.400"
                      _hover={{ bg: "blackAlpha.600" }}
                      zIndex={2}
                    />

                    <Box flex="1" display="flex" justifyContent="center">
                      {renderSelectedMedia(media[selectedMediaIndex])}
                    </Box>

                    <IconButton
                      aria-label="Next"
                      icon={<ChevronRightIcon boxSize={6} />}
                      onClick={() => handleNavigate("next")}
                      position="absolute"
                      right={4}
                      top="50%"
                      transform="translateY(-50%)"
                      variant="ghost"
                      color="white"
                      size="sm"
                      borderRadius="full"
                      bg="blackAlpha.400"
                      _hover={{ bg: "blackAlpha.600" }}
                      zIndex={2}
                    />
                  </Box>

                  <Flex
                    py={3}
                    px={6}
                    bg="gray.800"
                    borderTop="1px"
                    borderColor="gray.700"
                    justify="space-between"
                    align="center"
                  >
                    <VStack align="start" spacing={1}>
                      <HStack spacing={8} align="start">
                        <VStack align="start" spacing={1}>
                          <Text fontSize="sm" color="gray.300" letterSpacing="wide">
                            PRICE
                          </Text>
                          <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color="white"
                            letterSpacing="tight"
                          >
                            ${(media[selectedMediaIndex].price / 100).toFixed(2)}
                          </Text>
                        </VStack>

                        <VStack align="start" spacing={1}>
                          <Text fontSize="sm" color="gray.300" letterSpacing="wide">
                            RESOLUTION
                          </Text>
                          <Text
                            fontSize="lg"
                            fontWeight="semibold"
                            color="white"
                            letterSpacing="tight"
                          >
                            {media[selectedMediaIndex].is_processed &&
                            media[selectedMediaIndex].width &&
                            media[selectedMediaIndex].height
                              ? `${media[selectedMediaIndex].width} × ${media[selectedMediaIndex].height}`
                              : 'Processing...'}
                          </Text>
                        </VStack>
                      </HStack>

                      {!isOwner && (
                        <Link
                          as={RouterLink}
                          to={`/photographer/${media[selectedMediaIndex].photographer}`}
                          color="accent.300"
                          fontSize="sm"
                          _hover={{
                            color: 'accent.200',
                            textDecoration: 'none',
                          }}
                          mt={2}
                        >
                          <Text>
                            by {media[selectedMediaIndex].photographer}
                          </Text>
                        </Link>
                      )}
                    </VStack>

                    {isOwner ? (
                      isEditingPrice ? (
                        <HStack spacing={3}>
                          <Box position="relative">
                            <Text
                              position="absolute"
                              left={3}
                              top="50%"
                              transform="translateY(-50%)"
                              color="gray.400"
                              zIndex={2}
                            >
                              $
                            </Text>
                            <NumberInput
                              value={newPrice}
                              onChange={handlePriceChange}
                              min={1}
                              precision={2}
                              step={0.01}
                              size="sm"
                              width="120px"
                            >
                              <NumberInputField
                                pl={7}
                                color="white"
                                borderColor="gray.600"
                                _hover={{ borderColor: "gray.500" }}
                                _focus={{
                                  borderColor: "accent.300",
                                  boxShadow: "none",
                                }}
                                bg="gray.700"
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper
                                  color="gray.400"
                                  borderColor="gray.600"
                                />
                                <NumberDecrementStepper
                                  color="gray.400"
                                  borderColor="gray.600"
                                />
                              </NumberInputStepper>
                            </NumberInput>
                          </Box>
                          <Button
                            onClick={handleSavePrice}
                            size="sm"
                            colorScheme="accent"
                            variant="solid"
                            fontWeight="medium"
                          >
                            Save
                          </Button>
                        </HStack>
                      ) : (
                        <HStack spacing={2}>
                          <IconButton
                            aria-label="Edit price"
                            icon={<EditIcon boxSize={4} />}
                            onClick={handleEditPriceClick}
                            size="sm"
                            variant="ghost"
                            color="gray.400"
                            _hover={{ color: "white", bg: "whiteAlpha.200" }}
                          />
                          <IconButton
                            aria-label="Delete media"
                            icon={<DeleteIcon boxSize={4} />}
                            onClick={handleDeleteClick}
                            size="sm"
                            variant="ghost"
                            color="gray.400"
                            _hover={{ color: "red.300", bg: "whiteAlpha.200" }}
                          />
                        </HStack>
                      )
                    ) : (
                      <Button
                        onClick={handleBuyClick}
                        size="sm"
                        variant="solid"
                        color="white"
                        bg="black"
                        borderColor="accent.500"
                        borderWidth="1px"
                        fontWeight="medium"
                        px={6}
                        _hover={{
                          transform: "translateY(-1px)",
                          shadow: "lg",
                        }}
                        transition="all 0.2s"
                      >
                        Buy Now
                      </Button>
                    )}
                  </Flex>
                </VStack>
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MediaGrid;
