import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Spinner,
  Alert,
  Container,
  Button,
  Flex,
  Icon,
  useColorModeValue,
  Progress,
  AlertIcon,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaPencilAlt, FaRegPlusSquare } from "react-icons/fa";
import { getPhotographerProfile } from "../services/users";
import { getAlbumsByPhotographer } from "../services/albums";
import { Album } from "../types/album";
import AlbumCard from "../components/AlbumCard";
import useAuth from "../hooks/useAuth";

const PhotographerProfilePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [profile, setProfile] = useState<any>(null);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useAuth();

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const locationColor = useColorModeValue("gray.600", "gray.400");

  const isOwnProfile = user?.username === username;

  useEffect(() => {
    const fetchProfileAndAlbums = async () => {
      try {
        const profileData = await getPhotographerProfile(username!);
        setProfile(profileData);
        const albumsData = await getAlbumsByPhotographer(username!);
        setAlbums(albumsData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProfileAndAlbums();
  }, [username]);

  if (loading) {
    return (
      <Flex height="50vh" align="center" justify="center">
        <Spinner size="xl" thickness="4px" color="accent.500" />
      </Flex>
    );
  }

  if (!profile) {
    return (
      <Container maxW="container.xl" py={10}>
        <Alert status="error" borderRadius="lg">
          Photographer not found.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Box
        bg={bgColor}
        borderRadius="xl"
        borderWidth="1px"
        borderColor={borderColor}
        p={8}
        mb={8}
        boxShadow="sm"
      >
        {isOwnProfile && user && (
          <Box mb={6}>
            <Flex justify="space-between" align="center" mb={2}>
              <Text>Storage Used</Text>
              <Text>
                {((user.storage_used || 0) / (1024 * 1024 * 1024)).toFixed(2)} GB of{" "}
                {((user.storage_limit || 0) / (1024 * 1024 * 1024)).toFixed(2)} GB
              </Text>
            </Flex>
            <Progress
              value={
                ((user.storage_used || 0) / (user.storage_limit || 1)) * 100
              }
              colorScheme={
                user.storage_used / user.storage_limit > 0.9
                  ? "red"
                  : user.storage_used / user.storage_limit > 0.7
                  ? "yellow"
                  : "green"
              }
              borderRadius="full"
            />
            {user.storage_used / user.storage_limit > 0.9 && (
              <Alert status="warning" mt={2} borderRadius="md">
                <AlertIcon />
                You're running low on storage space. Consider deleting some
                media to free up space.
              </Alert>
            )}
          </Box>
        )}

        <Flex 
          justify="space-between" 
          align="flex-start" 
          mb={6}
          direction={{ base: "column", md: "row" }}
          gap={4}
        >
          <VStack align="start" spacing={4}>
            <Heading size="2xl">{profile.username}</Heading>
            {profile.location && (
              <Flex align="center" color={locationColor}>
                <Icon as={FaMapMarkerAlt} mr={2} />
                <Text fontSize="lg">{profile.location}</Text>
              </Flex>
            )}
            {profile.bio && (
              <Text fontSize="lg" maxW="800px">
                {profile.bio}
              </Text>
            )}
          </VStack>
          {isOwnProfile && (
            <Button
              leftIcon={<FaPencilAlt />}
              onClick={() => navigate("/edit-profile")}
              colorScheme="accent"
              variant="outline"
              alignSelf={{ base: "flex-start", md: "flex-start" }}
            >
              Edit Profile
            </Button>
          )}
        </Flex>
      </Box>

      <Heading size="lg" mb={6}>
        Albums
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {isOwnProfile && (
          <Button
            height="100%"
            minHeight="200px"
            onClick={() => navigate('/dashboard/create-album')}
            fontSize="xl"
            leftIcon={<FaRegPlusSquare />}
          >
            Create New Album
          </Button>
        )}
        {albums.map((album) => (
          <AlbumCard
            key={album.id}
            album={album}
            onClick={() =>
              isOwnProfile
                ? navigate(`/dashboard/album/${album.id}`)
                : navigate(`/photographer/${username}/album/${album.id}`)
            }
          />
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default PhotographerProfilePage;
