// src/pages/ForgotPasswordPage.tsx
import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { requestPasswordReset } from "../../services/auth";
import { useNavigate } from "react-router-dom";

interface ForgotPasswordFormInputs {
  email: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPasswordPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormInputs>({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    try {
      await requestPasswordReset(data.email);
      toast({
        title: "Password reset email sent.",
        description: "Please check your email for further instructions.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error ||
        "Something went wrong. Please try again.";
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH="80vh" align="center" justify="center">
      <Box
        bg="white"
        p={8}
        rounded="xl"
        borderWidth={"2px"}
        borderColor={"accent.600"}
        shadow="lg"
        w={{ base: "90%", md: "500px" }}
      >
        <Heading mb={6} textAlign="center">
          Forgot Password
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="email" mb={6} isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input type="email" {...register("email")} />
            <Text color="red.500" mt={1}>
              {errors.email?.message}
            </Text>
          </FormControl>

          <Button width="full" type="submit" isLoading={isSubmitting}>
            Reset Password
          </Button>
        </form>
        <Text mt={4} textAlign="center">
          Remembered your password?{" "}
          <Button variant="link" onClick={() => navigate("/login")}>
            Login
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default ForgotPasswordPage;
