import React from "react";
import {
  Box,
  Flex,
  Button,
  Link as ChakraLink,
  useToast,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { getStripeDasboardLink } from "../services/stripe";

interface HeaderProps {
  isTransparent?: boolean;
  buttonColor?: "white" | "black";
}

const Header: React.FC<HeaderProps> = ({ isTransparent = false, buttonColor = "black" }) => {
  const { isAuthenticated, user, logout } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const photographerProfileUrl = `/photographer/${user?.username}`;

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleStripeAccountLink = async () => {
    try {
      const { url } = await getStripeDasboardLink();
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error getting Stripe dashboard link:", error);
      toast({
        title: "Error",
        description: "Failed to get Stripe account link. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const menuItems = (
    <>
      <MenuItem as={RouterLink} to="/media" color="black">
        SEARCH
      </MenuItem>
      {isAuthenticated ? (
        <>
          <MenuItem as={RouterLink} to={photographerProfileUrl} color="black">
            PROFILE
          </MenuItem>
          {user?.is_photographer && (
            <MenuItem onClick={handleStripeAccountLink} color="black">MY SALES</MenuItem>
          )}
          <MenuItem onClick={handleLogout} color="black">LOGOUT</MenuItem>
        </>
      ) : (
        <>
          <MenuItem as={RouterLink} to="/login" color="black">
            LOGIN
          </MenuItem>
          <MenuItem as={RouterLink} to="/signup" color="black">
            SIGN UP
          </MenuItem>
        </>
      )}
    </>
  );

  const desktopButtons = (
    <>
      <Button
        as={RouterLink}
        to="/media"
        mr={isTransparent ? 8 : 4}
        variant={"link"}
        color={buttonColor}
        borderColor={"accent.600"}
        fontSize={isTransparent ? "xl" : "md"}
      >
        BROWSE
      </Button>
      {isAuthenticated ? (
        <>
          {user?.is_photographer && (
            <>
              <Button
                onClick={handleStripeAccountLink}
                mr={isTransparent ? 8 : 4}
                variant={"link"}
                color={buttonColor}
                fontSize={isTransparent ? "xl" : "md"}
              >
                SALES
              </Button>
              <Button
                as={RouterLink}
                to={photographerProfileUrl}
                mr={isTransparent ? 8 : 4}
                variant={"link"}
                color={buttonColor}
                fontSize={isTransparent ? "xl" : "md"}
              >
                PROFILE
              </Button>
            </>
          )}
          <Button
            onClick={handleLogout}
            variant={"link"}
            color={buttonColor}
            fontSize={isTransparent ? "xl" : "md"}
          >
            LOGOUT
          </Button>
        </>
      ) : (
        <>
          <Button
            as={RouterLink}
            to="/login"
            mr={isTransparent ? 8 : 4}
            variant={"link"}
            color={buttonColor}
            fontSize={isTransparent ? "xl" : "md"}
          >
            LOGIN
          </Button>
          <Button
            as={RouterLink}
            to="/signup"
            variant={"link"}
            color={buttonColor}
            fontSize={isTransparent ? "xl" : "md"}
          >
            SIGN UP
          </Button>
        </>
      )}
    </>
  );

  return (
    <Box
      as="header"
      bg={isTransparent ? "transparent" : "brand.50"}
      py={4}
      position="absolute"
      width="100%"
      zIndex={1000}
      px={"4vw"}
      pointerEvents="auto"
    >
      <Flex 
        mx="auto" 
        alignItems="center" 
        justifyContent={isTransparent && !isMobile ? "center" : "space-between"}
      >
        {!isTransparent && (
          <ChakraLink
            as={RouterLink}
            to="/"
            fontWeight="bold"
            fontSize="xl"
            color={isTransparent ? "black" : "inherit"}
            _hover={{
              textDecoration: "none",
              color: isTransparent ? "black" : "brand.600",
            }}
            display="flex"
            alignItems="center"
          >
            <Image src="/logo.png" alt="Logo" boxSize="40px" mr={2} />
            <Image src="/Swelter_Header.svg" alt="Logo" width="200px" mr={2} />
          </ChakraLink>
        )}
        {isMobile ? (
          <Box ml={isTransparent ? "auto" : "0"}>
            <Menu isOpen={isOpen} onClose={onClose}>
              <MenuButton
                as={IconButton}
                icon={<HamburgerIcon />}
                variant="outline"
                onClick={onOpen}
                aria-label="Options"
                colorScheme={isTransparent ? "black" : "gray"}
              />
              <MenuList>{menuItems}</MenuList>
            </Menu>
          </Box>
        ) : (
          <Flex>{desktopButtons}</Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
