import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmail } from '../../services/auth';
import { Box, Spinner, Text } from '@chakra-ui/react';

const VerifyEmailPage: React.FC = () => {
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState<string>('Verifying your email...');

  useEffect(() => {
    const verify = async () => {
      try {
        await verifyEmail(uidb64!, token!);
        setStatusMessage('Your email has been verified! Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error: any) {
        setStatusMessage('Email verification failed. The link may have expired or is invalid.');
      }
    };

    if (uidb64 && token) {
      verify();
    } else {
      setStatusMessage('Invalid verification link.');
    }
  }, [uidb64, token, navigate]);

  return (
    <Box textAlign="center" mt={10}>
      <Spinner size="xl" />
      <Text mt={5}>{statusMessage}</Text>
    </Box>
  );
};

export default VerifyEmailPage;
