import { Media } from '../types/media';
import api from './api';

export interface SearchMediaParams {
  surfBreakId: string;
  startDateTime: string;
  endDateTime: string;
}


export const getMedia = async (): Promise<Media[]> => {
  const response = await api.get('/api/media/');
  return response.data;
};

export const getMediaById = async (id: string): Promise<Media> => {
  const response = await api.get(`/api/media/${id}/`);
  return response.data;
};

export const deleteMedia = async (mediaId: string): Promise<void> => {
  await api.delete(`/api/media/${mediaId}/`);
};

export const uploadMedia = async (
  albumId: string,
  file: File,
  onProgress?: (progress: number) => void
): Promise<Media> => {
  const formData = new FormData();
  formData.append('album', albumId);
  formData.append('file', file);
  formData.append('media_type', file.type.startsWith('image/') ? 'photo' : 'video');

  const response = await api.post<Media>('/api/media/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (onProgress && progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    },
  });

  return response.data;
};


export const searchMedia = async (params: SearchMediaParams): Promise<Media[]> => {
  const response = await api.get('/api/media/', {params});
  return response.data;
}

export const updateMediaPrice = async (mediaId: string, newPrice: number): Promise<Media> => {
  const response = await api.patch<Media>(`/api/media/${mediaId}/`, { price: newPrice });
  return response.data;
};