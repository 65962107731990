import React from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  Button,
  Link,
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import {
  FaCamera,
  FaCloudUploadAlt,
  FaSearch,
  FaMoneyBillWave,
} from "react-icons/fa";

const PhotographerLandingPage: React.FC = () => {
  // Theme-aware color values
  const iconColor = useColorModeValue("accent.500", "accent.200");
  const buttonBg = useColorModeValue("accent.500", "accent.200");
  const cardBorderColor = useColorModeValue("accent.500", "gray.600");
  const pricingColor = useColorModeValue("accent.500", "accent.200");
  const bgColor = useColorModeValue("brand.50", "gray.800");
  const cardBg = useColorModeValue("white", "gray.700");
  const primaryTextColor = useColorModeValue("gray.800", "white");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Box
      paddingTop={{ base: "10px", md: "20px" }}
      bg={bgColor}
      px={{ base: 4, md: 8 }}
    >
      <Heading
        as="h1"
        size="lg"
        color={primaryTextColor}
        maxW="1200px"
        mx="auto"
        mb={8}
      >
        Capture the wave. Get paid.
      </Heading>

      <Flex
        direction={{ base: "column", md: "row" }}
        align="stretch"
        maxW="1200px"
        mx="auto"
        gap={8}
      >
        {/* Left side - Styled Card */}
        <Card
          flex="1"
          borderRadius="xl"
          overflow="hidden"
          boxShadow="lg"
          border="2px solid"
          borderColor={cardBorderColor}
          bg={cardBg}
          color={primaryTextColor}
          order={{ base: 2, md: 1 }}
        >
          <CardHeader>
            <Heading size="lg" color={primaryTextColor}>
              How It Works
            </Heading>
          </CardHeader>
          <CardBody>
            <List spacing={4}>
              <ListItem>
                <ListIcon as={FaCamera} color={iconColor} />
                Capture epic surf moments
              </ListItem>
              <ListItem>
                <ListIcon as={FaCloudUploadAlt} color={iconColor} />
                Upload your content to Swelter
              </ListItem>
              <ListItem>
                <ListIcon as={FaSearch} color={iconColor} />
                Surfers find and purchase your work
              </ListItem>
              <ListItem>
                <ListIcon as={FaMoneyBillWave} color={iconColor} />
                Get paid for your talent
              </ListItem>
            </List>
          </CardBody>
          <CardFooter>
            <Box>
              <Heading size="md" mb={2} color={primaryTextColor}>
                Transparent Pricing
              </Heading>
              <Text fontSize="2xl" fontWeight="bold" color={pricingColor}>
                $0 / month
              </Text>
              <Text color={secondaryTextColor}>25% platform fee per sale</Text>
            </Box>
          </CardFooter>
        </Card>

        {/* Right side - Call to Action */}
        <VStack
          flex="1"
          spacing={8}
          align="center"
          justify="center"
          order={{ base: 1, md: 2 }}
        >
          <Text
            fontSize="lg"
            textAlign="center"
            color={secondaryTextColor}
            maxW="500px"
          >
            Join a community of passionate surfers and photographers. Get out
            there, get the shot, and start earning for doing what you love.
          </Text>
          <Stack
            direction="column"
            spacing={4}
            width="100%"
            maxW="320px"
            align="center"
          >
            <Button
              as={Link}
              href="/signup"
              variant="outline"
              borderWidth="2px"
              color="black"
              borderColor="accent.500"
              size="lg"
              height="60px"
              width="100%"
              fontSize="xl"
              borderRadius="xl"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "xl",
                bg: "accent.50",
                borderColor: "accent.600",
              }}
              _active={{
                transform: "translateY(0)",
                opacity: 0.8,
              }}
            >
              Apply to be a Photographer
            </Button>
            <Button
              as={Link}
              href="/login"
              variant="link"
              color={"black"}
              size="lg"
              height="60px"
              fontSize="xl"
              _hover={{
                textDecoration: "none",
                opacity: 0.8,
              }}
            >
              Login
            </Button>
          </Stack>
        </VStack>
      </Flex>
    </Box>
  );
};

export default PhotographerLandingPage;
