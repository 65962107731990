import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { loginUser } from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { getCurrentUserProfile } from "../../services/users";

interface LoginFormInputs {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  const { login } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const response = await loginUser(data.email, data.password);
      login(response.access, response.refresh);
      navigate("/dashboard");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.detail ||
        (error.response?.status === 401 || error.response?.status == 400
          ? "Invalid credentials or email not verified."
          : "Something went wrong.");
      toast({
        title: "Login failed.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH="80vh" align="center" justify="center">
      <Box
        bg="white"
        p={8}
        rounded="xl"
        borderWidth={"2px"}
        borderColor={"accent.600"}
        shadow="lg"
        w={{ base: "90%", md: "500px" }}
      >
        <Heading mb={6} textAlign="center">
          Photographer Login
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="email" mb={4} isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input type="email" {...register("email")} autoComplete="email" />
            <Text color="red.500" mt={1}>
              {errors.email?.message}
            </Text>
          </FormControl>

          <FormControl id="password" mb={6} isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              {...register("password")}
              autoComplete="current-password"
            />
            <Text color="red.500" mt={1}>
              {errors.password?.message}
            </Text>
          </FormControl>

          <Button width="full" type="submit" isLoading={isSubmitting}>
            Login
          </Button>
        </form>
        <Text mt={4} textAlign="center">
          Don't have an account?{" "}
          <Button variant="link" onClick={() => navigate("/signup")}>
            Sign Up
          </Button>
        </Text>
        <Text mt={4} textAlign="center">
          Forgot your password?{" "}
          <Button variant="link" onClick={() => navigate("/forgot-password")}>
            Reset Password
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default LoginPage;
