import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  VStack,
  Flex,
  Divider,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SurfBreakSelector from "./SurfBreakSelector";
import { SurfBreak } from "../types/surfbreak";
import TimeRangeSelector from "./TimeRangeSelector";
import DateSelector from "./DateSelector";
import { format } from "date-fns";

interface FilterProps {
  onFilter: (filters: FilterValues) => void;
  initialValues?: FilterValues;
}

export interface FilterValues {
  surfBreakId: string;
  date: string;
  startTime: string;
  endTime: string;
}

const schema = yup.object().shape({
  surfBreakId: yup.string().required("Surf Spot is required"),
  date: yup.string().required("Date is required"),
  startTime: yup.string().required("Start Time is required"),
  endTime: yup.string().required("End Time is required"),
});

const MediaSearchFilter: React.FC<FilterProps> = ({
  onFilter,
  initialValues,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FilterValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const [selectedBreak, setSelectedBreak] = useState<SurfBreak | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const isStacked = useBreakpointValue({ base: true, md: false });

  const handleBreakSelect = (surfBreak: SurfBreak) => {
    setSelectedBreak(surfBreak);
    setValue("surfBreakId", surfBreak.id);
  };

  const handleDateSelect = (date: Date | null) => {
    setSelectedDate(date);
    setValue("date", date ? format(date, "yyyy-MM-dd") : "");
  };

  const handleTimeRangeSelect = (start: string, end: string) => {
    setStartTime(start);
    setEndTime(end);
    setValue("startTime", start);
    setValue("endTime", end);
  };

  const onSubmit = (data: FilterValues) => {
    onFilter(data);
  };

  return (
    <Center width="100%">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        bg="white"
        borderRadius="xl"
        borderColor={"accent.600"}
        borderWidth={2}
        p={4}
        shadow="lg"
        width={isStacked ? "100%" : "100%"}
        maxWidth={isStacked ? "500px" : "none"}
      >
        <Flex
          direction={isStacked ? "column" : "row"}
          align="stretch"
          justify="space-between"
        >
          <FormControl isInvalid={!!errors.surfBreakId} flex={1}>
            <Controller
              name="surfBreakId"
              control={control}
              render={({ field }) => (
                <SurfBreakSelector onSelect={handleBreakSelect} />
              )}
            />
            <FormErrorMessage>{errors.surfBreakId?.message}</FormErrorMessage>
          </FormControl>

          {!isStacked && <Divider orientation="vertical" mx={4} />}
          {isStacked && <Divider my={4} />}

          <FormControl isInvalid={!!errors.date} flex={1}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DateSelector onSelect={handleDateSelect} />
              )}
            />
            <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
          </FormControl>

          {!isStacked && <Divider orientation="vertical" mx={4} />}
          {isStacked && <Divider my={4} />}

          <FormControl
            isInvalid={!!errors.startTime || !!errors.endTime}
            flex={1}
          >
            <Controller
              name="startTime"
              control={control}
              render={({ field }) => (
                <TimeRangeSelector onSelect={handleTimeRangeSelect} />
              )}
            />
            <FormErrorMessage>
              {errors.startTime?.message || errors.endTime?.message}
            </FormErrorMessage>
          </FormControl>

          {!isStacked && <Divider orientation="vertical" mx={4} />}
          {isStacked && <Divider my={4} />}

          <Box
            alignSelf="center"
            mt={isStacked ? 0 : 0}
            width={isStacked ? "full" : "auto"}
          >
            <Button
              type="submit"
              size="md"
              borderRadius="xl"
              width={isStacked ? "full" : "auto"}
            >
              Search
            </Button>
          </Box>
        </Flex>
      </Box>
    </Center>
  );
};

export default MediaSearchFilter;
