import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  Link,
  HStack,
} from "@chakra-ui/react";
import { getCurrentUserProfile, updateProfile } from "../services/users";

const EditProfilePage: React.FC = () => {
  const [formData, setFormData] = useState({ location: "", bio: "" });
  const toast = useToast();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await getCurrentUserProfile();
        setFormData({ location: profileData.location, bio: profileData.bio });
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };
    fetchProfile();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProfile(formData);
      toast({
        title: "Profile updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  return (
    <Box p={4} maxW="600px" mx="auto">
      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>Location</FormLabel>
          <Input
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl mb={4}>
          <FormLabel>Bio</FormLabel>
          <Textarea name="bio" value={formData.bio} onChange={handleChange} />
        </FormControl>
        <HStack>
          <Button type="submit" colorScheme="accent">
            Save
          </Button>
          <Button onClick={() => window.history.back()}>Back</Button>
        </HStack>
      </form>
    </Box>
  );
};

export default EditProfilePage;
