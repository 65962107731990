// src/pages/ResetPasswordPage.tsx
import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { resetPassword } from "../../services/auth";
import { useNavigate, useParams } from "react-router-dom";

interface ResetPasswordFormInputs {
  password: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPasswordPage: React.FC = () => {
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordFormInputs>({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: ResetPasswordFormInputs) => {
    try {
      await resetPassword(uidb64!, token!, data.password);
      toast({
        title: "Password reset successful.",
        description: "You can now log in with your new password.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error ||
        "Invalid or expired token. Please request a new password reset.";
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate("/forgot-password");
    }
  };

  return (
    <Flex minH="80vh" align="center" justify="center">
      <Box
        bg="white"
        p={8}
        rounded="xl"
        borderWidth={"2px"}
        borderColor={"accent.600"}
        shadow="lg"
        w={{ base: "90%", md: "500px" }}
      >
        <Heading mb={6} textAlign="center">
          Reset Password
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="password" mb={4} isInvalid={!!errors.password}>
            <FormLabel>New Password</FormLabel>
            <Input type="password" {...register("password")} />
            <Text color="red.500" mt={1}>
              {errors.password?.message}
            </Text>
          </FormControl>

          <FormControl
            id="confirmPassword"
            mb={6}
            isInvalid={!!errors.confirmPassword}
          >
            <FormLabel>Confirm New Password</FormLabel>
            <Input type="password" {...register("confirmPassword")} />
            <Text color="red.500" mt={1}>
              {errors.confirmPassword?.message}
            </Text>
          </FormControl>

          <Button width="full" type="submit" isLoading={isSubmitting}>
            Reset Password
          </Button>
        </form>
        <Text mt={4} textAlign="center">
          Remembered your password?{" "}
          <Button variant="link" onClick={() => navigate("/login")}>
            Login
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default ResetPasswordPage;
