import React, { useRef } from "react";
import {
  Box,
  Heading,
  Button,
  VStack,
  Flex,
  Container,
  Text,
  Image,
  SimpleGrid,
  Icon,
  Stat,
  StatNumber,
  StatLabel,
  Avatar,
  Stack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaCamera, FaDownload, FaUsers, FaWaveSquare } from "react-icons/fa";
import { IconType } from "react-icons";
import SurferLandingPage from "./SurferLandingPage";
import PhotographerLandingPage from "./PhotographerLandingPage";

interface FeatureCardProps {
  icon: IconType;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => (
  <Box
    bg="white"
    p={8}
    borderRadius="xl"
    boxShadow="xl"
    border="2px"
    borderColor="accent.500"
    transition="all 0.3s"
    _hover={{ transform: "translateY(-5px)", boxShadow: "2xl" }}
  >
    <Icon as={icon} w={10} h={10} color="accent.500" mb={4} />
    <Heading size="md" mb={4}>
      {title}
    </Heading>
    <Text color="gray.600">{description}</Text>
  </Box>
);

const LandingPage: React.FC = () => {
  const surferRef = useRef<HTMLDivElement>(null);
  const photographerRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box bg="brand.50">
      {/* Hero Section */}
      <Box position="relative" height="100vh" overflow="hidden">
        {/* Video Background */}
        <Box position="relative" height="100%" width="100%">
          <Box
            as="video"
            autoPlay
            loop
            muted
            playsInline
            poster="/landing_video_poster.jpg"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src="/landing_video_720.mp4" type="video/mp4" />
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))"
            zIndex={1}
          />
        </Box>

        {/* Hero Content */}
        <Flex
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={2}
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          color="white"
          px={4}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Flex justify="center" align="center" mb={6}>
              <Image src="/logo.png" alt="Logo" h="60px" mr={4} />
              <Image src="/Swelter_Header.svg" alt="Swelter" h="100px" />
            </Flex>
            <Text fontSize="xl" mb={8} maxW="800px" mx="auto">
              Swelter connects you with local surf photographers who capture
              your greatest moments. Find and instantly download photos and
              videos of your sessions.
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={4}
              justify="center"
            >
              <Button
                size="lg"
                colorScheme="accent"
                onClick={() => scrollToSection(surferRef)}
                px={8}
                py={6}
                fontSize="lg"
                bg="brand.50"
                color="black"
                borderWidth="1px"
                borderColor="accent.500"
                _hover={{
                  bg: "accent.500",
                  color: "white",
                  transform: "translateY(-4px)",
                }}
                transition="all 0.3s ease"
              >
                Find Your Photos
              </Button>
              <Button
                size="lg"
                onClick={() => scrollToSection(photographerRef)}
                px={8}
                py={6}
                fontSize="lg"
                bg="brand.50"
                color="black"
                borderWidth="1px"
                borderColor="accent.500"
                _hover={{
                  bg: "accent.500",
                  color: "white",
                  transform: "translateY(-4px)",
                }}
                transition="all 0.3s ease"
              >
                Become a Photographer
              </Button>
            </Stack>
          </motion.div>
        </Flex>
      </Box>

      {/* Surfer Landing Page */}
      <Container maxW="container.xl" px={4} pt={10}>
        <Box ref={surferRef}>
          <SurferLandingPage />
        </Box>
      </Container>

      {/* Features Section - Moved under Surfer Landing Page */}
      <Box py={20}>
        <Container maxW="container.xl">
          <VStack spacing={16}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 4 }}
              spacing={8}
              w="full"
            >
              <FeatureCard
                icon={FaCamera}
                title="Professional Photographers"
                description="Connect with talented local photographers who specialize in surf photography"
              />
              <FeatureCard
                icon={FaDownload}
                title="Instant Downloads"
                description="Get immediate access to high-resolution photos and videos"
              />
              <FeatureCard
                icon={FaWaveSquare}
                title="Any Break, Any Time"
                description="Find photos from your favorite surf spots and sessions"
              />
              <FeatureCard
                icon={FaUsers}
                title="Growing Community"
                description="Join hundreds of surfers and photographers already using Swelter"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* Photographer Landing Page */}
      <Container maxW="container.xl" px={4} pb={40}>
        <Box ref={photographerRef}>
          <PhotographerLandingPage />
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
