import React from 'react';
import { Box, Link, Text, HStack } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

interface FooterProps {
  isTransparent?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isTransparent = false }) => {
  return (
    <Box
      as="footer"
      bg={isTransparent ? 'transparent' : 'brand.50'}
      py={4}
      textAlign="center"
      width="100%"
      position={isTransparent ? 'absolute' : 'relative'}
      bottom={0}
      left={0}
      zIndex={1000}
    >
      <HStack spacing={4} justify="center">
        <Text color={isTransparent ? 'black' : 'inherit'}>
          &copy; 2024 Swelter. All rights reserved.
        </Text>
        <Link href="/privacy" color={isTransparent ? 'black' : 'inherit'}>
          Privacy Policy
        </Link>
        <Link href="/tos" color={isTransparent ? 'black' : 'inherit'}>
          Terms of Service
        </Link>
        <Link 
          href="https://www.instagram.com/sweltersurf/" 
          isExternal 
          color={isTransparent ? 'black' : 'inherit'}
          display="flex"
          alignItems="center"
        >
          <FaInstagram style={{ marginRight: '4px' }} />
          @sweltersurf
        </Link>
      </HStack>
    </Box>
  );
};

export default Footer;