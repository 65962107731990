import api from './api';

interface CreateCheckoutSessionResponse {
  client_secret: string;
}

export const createCheckoutSession = async (mediaId: string): Promise<CreateCheckoutSessionResponse> => {
  const response = await api.post('/api/create-checkout-session/', { media_id: mediaId });
  return response.data;
};

export const getStripeAccountLink = async (): Promise<{ url: string }> => {
  const response = await api.post('/api/create-account-link/');
  return response.data;
};

export const getStripeDasboardLink = async (): Promise<{ url: string }> => {
  const response = await api.post('/api/stripe-dashboard-link/');
  return response.data;
};