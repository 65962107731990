import { extendTheme } from '@chakra-ui/react';
import '@fontsource/inknut-antiqua';

const theme = extendTheme({
  fonts: {
    heading: `'Inknut Antiqua', serif`,
    body: `'Arial', sans-serif`,
  },
  colors: {
    brand: {
      50: '#FFFEF0',
      100: '#FFFDE8',
      200: '#FFFAC2',
      300: '#FFF79C',
      400: '#FFF476',
      500: '#FFF150', // Primary brand color
      600: '#DBCF40',
      700: '#B7AD30',
      800: '#938B20',
      900: '#7A7314',
    },
    accent: {
      50: '#FFE5E5',   // Lightest - very light red
      100: '#FFB8B8',  // Lighter
      200: '#FF8C8C',  // Light
      300: '#FF5C5C',  // Soft red
      400: '#FF2E2E',  // Bright red
      500: '#FF0000',  // Primary accent color (pure red)
      600: '#DB0000',  // Darker red
      700: '#B70000',  // Deep red
      800: '#930000',  // Very deep red
      900: '#7A0000',  // Darkest red
    },
  },
  styles: {
    global: (props: { colorMode: string }) => ({
      body: {
        bg: props.colorMode === 'light' ? 'brand.50' : 'gray.800',
        color: props.colorMode === 'light' ? 'gray.800' : 'white',
      },
    }),
  },
});

export default theme;