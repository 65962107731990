import api from './api';

export interface OrderDetails {
  id: string;
  status: 'pending' | 'completed' | 'failed' | 'expired';
  media_url: string | null;
  media_type: 'photo' | 'video';
  is_paid: boolean;
  total_amount: number;
  created_at: string;
}

// Get order details by session ID
export const getOrderBySessionId = async (sessionId: string): Promise<OrderDetails> => {
  const response = await api.get('/api/orders/session', {
    params: {
      session_id: sessionId
    }
  });
  return response.data;
};
