import React from "react";
import { Box, Container, Flex } from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

interface LayoutProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  isLandingPage?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  fullWidth = false,
  isLandingPage = false,
}) => {
  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      width="100%"
      position="relative"
    >
      <Header isTransparent={isLandingPage} buttonColor={"black"} />
      <Box as="main" flex={1} width="100%" pt={isLandingPage ? 0 : "72px"}>
        {fullWidth ? (
          children
        ) : (
          <Container maxW="container.xl" py={8}>
            {children}
          </Container>
        )}
      </Box>
      <Footer isTransparent={isLandingPage} />
      <Analytics />
      <SpeedInsights />
    </Flex>
  );
};

export default Layout;
