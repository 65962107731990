import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Spinner, Center } from '@chakra-ui/react';
import useAuth from '../../hooks/useAuth';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.username) {
      navigate(`/photographer/${user.username}`);
    }
  }, [user, navigate]);

  return (
    <Center h="80vh">
      <Spinner size="xl" color="accent.500" thickness="4px" />
    </Center>
  );
};

export default DashboardPage;
