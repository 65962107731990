import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Heading,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Card,
  CardHeader,
  CardBody,
  Divider,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format } from "date-fns";
import { createAlbum } from "../../services/albums";
import SurfBreakSelector from "../../components/SurfBreakSelector";
import DateSelector from "../../components/DateSelector";
import TimeRangeSelector from "../../components/TimeRangeSelector";
import { SurfBreak } from "../../types/surfbreak";

interface CreateAlbumFormInputs {
  surf_break: string;
  date: string;
  start_time: string;
  end_time: string;
  default_photo_price: number;
  default_video_price: number;
}

const schema = yup.object().shape({
  surf_break: yup.string().required("Surf Spot is required"),
  date: yup.string().required("Date is required"),
  start_time: yup.string().required("Start time is required"),
  end_time: yup.string().required("End time is required"),
  default_photo_price: yup
    .number()
    .min(1, "Price must be at least $1.00")
    .required("Default photo price is required"),
  default_video_price: yup
    .number()
    .min(1, "Price must be at least $1.00")
    .required("Default video price is required"),
});

const CreateAlbumForm: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<CreateAlbumFormInputs>({
    resolver: yupResolver(schema),
  });

  const [selectedBreak, setSelectedBreak] = useState<SurfBreak | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const toast = useToast();
  const navigate = useNavigate();

  const handleBreakSelect = (surfBreak: SurfBreak) => {
    setSelectedBreak(surfBreak);
    setValue("surf_break", surfBreak.id);
  };

  const handleDateSelect = (date: Date | null) => {
    setSelectedDate(date);
    setValue("date", date ? format(date, "yyyy-MM-dd") : "");
  };

  const handleTimeRangeSelect = (start: string, end: string) => {
    setValue("start_time", start);
    setValue("end_time", end);
  };

  const onSubmit = async (data: CreateAlbumFormInputs) => {
    try {
      const startDateTime = new Date(`${data.date}T${data.start_time}`);
      const endDateTime = new Date(`${data.date}T${data.end_time}`);

      if (endDateTime < startDateTime) {
        endDateTime.setDate(endDateTime.getDate() + 1);
      }

      const dataToSend = {
        surf_break: data.surf_break,
        start_datetime: startDateTime.toISOString(),
        end_datetime: endDateTime.toISOString(),
        default_photo_price: Math.round(data.default_photo_price * 100),
        default_video_price: Math.round(data.default_video_price * 100),
      };

      const newAlbum = await createAlbum(dataToSend);
      toast({
        title: "Album created.",
        description: "Your album has been successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      reset();
      navigate(`/dashboard/album/${newAlbum.id}`);
    } catch (error: any) {
      toast({
        title: "Album creation failed.",
        description: error.response?.data?.detail || "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="800px" mx="auto" py={8} px={4}>
      <Card variant="outline" borderWidth={2} borderColor="accent.600">
        <CardHeader>
          <Heading size="lg" color="black">
            Create New Album
          </Heading>
        </CardHeader>
        <CardBody>
          <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={6}>
            <FormControl isInvalid={!!errors.surf_break}>
              <FormLabel fontSize="md">Surf Spot</FormLabel>
              <Controller
                name="surf_break"
                control={control}
                render={({ field }) => (
                  <SurfBreakSelector onSelect={handleBreakSelect} />
                )}
              />
              <FormErrorMessage>{errors.surf_break?.message}</FormErrorMessage>
            </FormControl>

            <Divider />

            <FormControl isInvalid={!!errors.date}>
              <FormLabel fontSize="md">Day</FormLabel>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DateSelector onSelect={handleDateSelect} />
                )}
              />
              <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
            </FormControl>

            <Divider />

            <FormControl isInvalid={!!errors.start_time || !!errors.end_time}>
              <FormLabel fontSize="md">Time</FormLabel>
              <Controller
                name="start_time"
                control={control}
                render={({ field }) => (
                  <TimeRangeSelector onSelect={handleTimeRangeSelect} />
                )}
              />
              <FormErrorMessage>
                {errors.start_time?.message || errors.end_time?.message}
              </FormErrorMessage>
            </FormControl>

            <Divider />

            <FormControl isInvalid={!!errors.default_photo_price}>
              <FormLabel fontSize="md">Default Photo Price ($)</FormLabel>
              <Controller
                name="default_photo_price"
                control={control}
                render={({ field }) => (
                  <NumberInput min={1} precision={2} step={0.01} {...field}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
              <FormErrorMessage>
                {errors.default_photo_price?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.default_video_price}>
              <FormLabel fontSize="md">Default Video Price ($)</FormLabel>
              <Controller
                name="default_video_price"
                control={control}
                render={({ field }) => (
                  <NumberInput min={1} precision={2} step={0.01} {...field}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
              <FormErrorMessage>
                {errors.default_video_price?.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              isLoading={isSubmitting}
              variant="outline"
              borderColor="accent.500"
              color="black"
              size="lg"
              width="full"
            >
              Create Album
            </Button>
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};

export default CreateAlbumForm;
