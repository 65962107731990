import React from 'react';
import { Box, Text, Button, useToast } from '@chakra-ui/react';
import { resendVerificationEmail } from '../../services/auth';

const PleaseVerifyEmailPage: React.FC = () => {
  const toast = useToast();

  const handleResendEmail = async () => {
    try {
      await resendVerificationEmail();
      toast({
        title: 'Verification email resent!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Please try again later.';
      toast({
        title: 'Error resending email.',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box textAlign="center" mt={10}>
      <Text>Please verify your email to continue.</Text>
      <Button onClick={handleResendEmail} mt={4}>
        Resend Verification Email
      </Button>
    </Box>
  );
};

export default PleaseVerifyEmailPage;
