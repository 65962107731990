import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Container,
  Divider,
} from '@chakra-ui/react';

const TermsOfService = () => {
  const [currentDate] = useState(new Date().toLocaleDateString());

  return (
    <Container maxW="container.xl" py={10}>
      <VStack spacing={8} align="stretch">
        <Box>
          <Heading as="h1" size="2xl" mb={4}>Swelter Terms of Service</Heading>
          <Text>Last Updated: {currentDate}</Text>
        </Box>

        <Box>
          <Heading as="h2" size="xl" mb={4}>1. Acceptance of Terms</Heading>
          <Text>
            By accessing or using the Swelter website, mobile application, or any other services provided by Tarsier Technologies LLC (collectively, the "Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Service.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>2. Changes to Terms</Heading>
          <Text>
            We reserve the right to modify these Terms at any time. We will provide notice of significant changes by posting an announcement on our website or by sending you an email. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>3. Service Description</Heading>
          <Text mb={2}>
            3.1. Swelter is a platform that enables photographers to upload and sell photos and videos of surfing, and allows surfers to purchase images of themselves.
          </Text>
          <Text>
            3.2. We reserve the right to change, suspend, or discontinue any aspect of the Service at any time, including hours of operation or availability of the Service or any feature, without notice and without liability.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>4. User Accounts</Heading>
          <List spacing={2}>
            <ListItem>
              4.1. You must create an account to use certain features of Swelter.
            </ListItem>
            <ListItem>
              4.2. You are responsible for maintaining the confidentiality of your account information and password.
            </ListItem>
            <ListItem>
              4.3. You are responsible for all activities that occur under your account.
            </ListItem>
            <ListItem>
              4.4. You agree to immediately notify us of any unauthorized use of your account or any other breach of security.
            </ListItem>
            <ListItem>
              4.5. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our sole discretion.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>5. User Conduct</Heading>
          <Text mb={2}>You agree not to:</Text>
          <List spacing={2}>
            <ListItem>
              5.1. Use the Service for any illegal purpose or in violation of any local, state, national, or international law.
            </ListItem>
            <ListItem>
              5.2. Harass, abuse, or harm another person.
            </ListItem>
            <ListItem>
              5.3. Impersonate or misrepresent your affiliation with any person or entity.
            </ListItem>
            <ListItem>
              5.4. Interfere with or disrupt the Service or servers or networks connected to the Service.
            </ListItem>
            <ListItem>
              5.5. Attempt to gain unauthorized access to any portion of the Service or any other systems or networks connected to the Service.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>6. Content</Heading>
          <List spacing={2}>
            <ListItem>
              6.1. Users may upload photos and videos related to surfing ("User Content").
            </ListItem>
            <ListItem>
              6.2. By uploading User Content, you grant Swelter a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the Service and Swelter's business, including for promoting and redistributing part or all of the Service.
            </ListItem>
            <ListItem>
              6.3. You retain all ownership rights to your User Content.
            </ListItem>
            <ListItem>
              6.4. Swelter reserves the right to use uploaded photos and videos for marketing purposes and to train machine learning models to improve our platform.
            </ListItem>
            <ListItem>
              6.5. You represent and warrant that: (i) you own the User Content or have the right to grant the license set forth in these Terms, (ii) the posting and use of your User Content does not violate the privacy rights, publicity rights, copyrights, contract rights, intellectual property rights, or any other rights of any person, and (iii) the posting of your User Content does not result in a breach of contract between you and a third party.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>7. Content Restrictions</Heading>
          <Text mb={2}>Users may not upload content that:</Text>
          <List spacing={2}>
            <ListItem>
              7.1. Is illegal, obscene, offensive, threatening, defamatory, invasive of privacy, or otherwise objectionable.
            </ListItem>
            <ListItem>
              7.2. Infringes any patent, trademark, trade secret, copyright, or other intellectual property or proprietary rights of any party.
            </ListItem>
            <ListItem>
              7.3. Contains software viruses or any other computer code designed to interrupt, destroy, or limit the functionality of any computer software or hardware.
            </ListItem>
            <ListItem>
              7.4. Impersonates any person or entity or misrepresents your affiliation with a person or entity.
            </ListItem>
            <ListItem>
              7.5. Contains unsolicited or unauthorized advertising, promotional materials, spam, junk mail, chain letters, or any other form of solicitation.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>8. Purchases and Sales</Heading>
          <List spacing={2}>
            <ListItem>
              8.1. Swelter facilitates transactions between photographers and surfers but is not responsible for the quality, safety, morality, or legality of any aspect of the items listed, the truth or accuracy of the listings, or the ability of sellers to sell items or the ability of buyers to pay for items.
            </ListItem>
            <ListItem>
              8.2. All sales are final unless otherwise stated or required by law.
            </ListItem>
            <ListItem>
              8.3. Swelter takes a commission on each sale, as specified in our current fee structure. We reserve the right to change our fee structure at any time.
            </ListItem>
            <ListItem>
              8.4. You are responsible for paying all fees and applicable taxes associated with using the Service.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>9. Intellectual Property Rights</Heading>
          <List spacing={2}>
            <ListItem>
              9.1. The Service and its original content, features, and functionality are owned by Tarsier Technologies LLC and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
            </ListItem>
            <ListItem>
              9.2. You may not copy, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Service, except as incidental to normal web browsing or as expressly permitted in these Terms.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>10. Copyright Infringement</Heading>
          <Text mb={2}>
            10.1. If you believe that any User Content violates your copyright, please see our Copyright Policy for instructions on sending us a notice of copyright infringement.
          </Text>
          <Text>
            10.2. Repeat infringers will have their accounts terminated.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>11. Disclaimer of Warranties</Heading>
          <List spacing={2}>
            <ListItem>
              11.1. Swelter provides the Service "as is" and "as available" without any warranties of any kind, express or implied.
            </ListItem>
            <ListItem>
              11.2. We do not warrant that the Service will be uninterrupted or error-free, that defects will be corrected, or that the Service or the server that makes it available are free of viruses or other harmful components.
            </ListItem>
            <ListItem>
              11.3. We do not guarantee the accuracy, completeness, or usefulness of any content on the platform.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>12. Limitation of Liability</Heading>
          <Text mb={2}>
            12.1. Tarsier Technologies LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
          </Text>
          <List spacing={2} ml={5}>
            <ListItem>
              a. Your access to or use of or inability to access or use the Service;
            </ListItem>
            <ListItem>
              b. Any conduct or content of any third party on the Service;
            </ListItem>
            <ListItem>
              c. Any content obtained from the Service; and
            </ListItem>
            <ListItem>
              d. Unauthorized access, use, or alteration of your transmissions or content.
            </ListItem>
          </List>
          <Text mt={2}>
            12.2. In no event shall our total liability to you for all damages, losses, or causes of action exceed the amount you have paid Swelter in the last six (6) months, or, if greater, one hundred dollars ($100).
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>13. Indemnification</Heading>
          <Text mb={2}>
            You agree to defend, indemnify, and hold harmless Tarsier Technologies LLC and its employees, contractors, agents, officers, and directors from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:
          </Text>
          <List spacing={2}>
            <ListItem>
              13.1. Your use of and access to the Service;
            </ListItem>
            <ListItem>
              13.2. Your violation of any term of these Terms;
            </ListItem>
            <ListItem>
              13.3. Your violation of any third-party right, including without limitation any copyright, property, or privacy right; or
            </ListItem>
            <ListItem>
              13.4. Any claim that your User Content caused damage to a third party.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>14. Termination</Heading>
          <List spacing={2}>
            <ListItem>
              14.1. We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
            </ListItem>
            <ListItem>
              14.2. If you wish to terminate your account, you may simply discontinue using the Service.
            </ListItem>
            <ListItem>
              14.3. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>15. Governing Law</Heading>
          <Text>
            These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>16. Dispute Resolution</Heading>
          <Text mb={2}>
            16.1. Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in [Your City, State], using the English language in accordance with the Arbitration Rules and Procedures of JAMS then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes.
          </Text>
          <Text>
            16.2. The prevailing party in any arbitration or other proceeding arising under these Terms shall be entitled to receive reimbursement of its reasonable expenses (including reasonable attorneys' fees, expert witness fees, and all other expenses) incurred in connection therewith.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>17. Severability</Heading>
          <Text>
            If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>18. Entire Agreement</Heading>
          <Text>
            These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="xl" mb={4}>19. Contact Us</Heading>
          <Text>If you have any questions about these Terms, please contact us at:</Text>
          <Text mt={2}>
            Tarsier Technologies LLC<br />
            contact@tarsiertech.io<br />
            (424) 744-3368
          </Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default TermsOfService;