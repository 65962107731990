import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Image,
  Button,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  AspectRatio,
  useToast,
  Flex,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { getOrderBySessionId, OrderDetails } from "../services/order";

// Define all possible order statuses
type OrderStatus = "pending" | "completed" | "failed" | "expired";

// Define final statuses explicitly
const FINAL_STATUSES = ["completed", "failed", "expired"] as const;
type FinalStatus = (typeof FINAL_STATUSES)[number];

// Type guard to check if status is final
const isFinalStatus = (status: OrderStatus): status is FinalStatus => {
  return FINAL_STATUSES.includes(status as FinalStatus);
};

const POLLING_INTERVAL = 3000; // Poll every 3 seconds

const OrderDetailsPage = () => {
  const { sessionId } = useParams();
  const [order, setOrder] = useState<OrderDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const fetchOrder = useCallback(async () => {
    try {
      if (!sessionId) throw new Error("No session ID provided");
      const orderData = await getOrderBySessionId(sessionId);
      setOrder(orderData);
      return orderData;
    } catch (error) {
      toast({
        title: "Error loading order",
        description: "Unable to load order details. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, [sessionId, toast]);

  useEffect(() => {
    let intervalId: number;

    const pollOrder = async () => {
      const orderData = await fetchOrder();

      // If order is in a final state or there's an error, stop polling
      if (!orderData || isFinalStatus(orderData.status)) {
        clearInterval(intervalId);
      }
    };

    // Initial fetch
    pollOrder();

    // Start polling if needed
    intervalId = window.setInterval(pollOrder, POLLING_INTERVAL);

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchOrder]);

  const handleDownload = () => {
    if (!order?.media_url) return;

    // Create a link element and simulate click
    const link = document.createElement("a");
    link.href = order.media_url;
    link.target = "_blank"; // Optional: open in new tab
    // Set suggested filename for the download
    link.download = `surf-media-${order.id}.${
      order.media_type === "photo" ? "jpg" : "mp4"
    }`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="xl" color="accent.500" thickness="4px" />
      </Flex>
    );
  }

  if (!order) {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error" borderRadius="lg">
          <AlertIcon />
          <AlertTitle>Order not found</AlertTitle>
          <AlertDescription>
            This order doesn't exist or has been removed.
          </AlertDescription>
        </Alert>
      </Container>
    );
  }

  if (order.status === "failed" || order.status === "expired") {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error" borderRadius="lg">
          <AlertIcon />
          <AlertTitle>Order {order.status}</AlertTitle>
          <AlertDescription>
            {order.status === "failed"
              ? "Your payment was unsuccessful. Please try purchasing again."
              : "This order has expired. Please make a new purchase."}
          </AlertDescription>
        </Alert>
      </Container>
    );
  }

  if (order.status === "pending") {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="info" borderRadius="lg">
          <AlertIcon />
          <AlertTitle>Processing your order</AlertTitle>
          <AlertDescription>
            Please wait while we process your payment. This page will update
            automatically.
          </AlertDescription>
        </Alert>
        <Box textAlign="center" mt={4}>
          <Spinner size="xl" color="accent.500" thickness="4px" />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Flex justify="space-between" align="center">
          <Heading size="lg">Your Purchase</Heading>
          {order.media_url && (
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="accent"
              onClick={handleDownload}
              size="lg"
            >
              Download HD Version
            </Button>
          )}
        </Flex>

        <Divider />

        <Box borderRadius="xl" overflow="hidden" bg="black" position="relative">
          {order.media_type === "photo" ? (
            <AspectRatio ratio={16 / 9} maxH="80vh">
              <Image
                src={order.media_url || ""}
                alt="Purchased surf photo"
                objectFit="contain"
              />
            </AspectRatio>
          ) : (
            <AspectRatio ratio={16 / 9} maxH="80vh">
              <video
                controls
                autoPlay
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              >
                <source src={order.media_url || ""} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </AspectRatio>
          )}
        </Box>

        <Box bg="white" p={6} borderRadius="lg" shadow="md">
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Order Details
          </Text>
          <Text>Order ID: {order.id}</Text>
          <Text>Amount Paid: ${(order.total_amount / 100).toFixed(2)}</Text>
          <Text>Date: {new Date(order.created_at).toLocaleString()}</Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default OrderDetailsPage;
