import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Heading,
  useToast,
  Text,
  VStack,
  Button,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { getAlbum, deleteAlbum } from "../../services/albums";
import { Album } from "../../types/album";
import { Media } from "../../types/media";
import MediaUploader from "../../components/MediaUploader";
import MediaGrid from "../../components/MediaGrid";
import { useAuth } from "../../context/AuthContext";

const AlbumDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [album, setAlbum] = useState<Album | null>(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isOwner = user?.username === album?.photographer;

  const [isDeleting, setIsDeleting] = useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const fetchAlbum = useCallback(async () => {
    try {
      const data = await getAlbum(id!);
      setAlbum(data);
      return data;
    } catch (error) {
      toast({
        title: "Error fetching album",
        description: "Unable to load album details. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, [id, toast]);

  const handleDeleteAlbum = async () => {
    try {
      await deleteAlbum(id!);
      toast({
        title: "Album deleted.",
        description: "Your album has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/dashboard");
    } catch (error: any) {
      toast({
        title: "Cannot Delete Album",
        description: error.response?.data?.[0] || 
                    error.response?.data?.detail || 
                    "Unable to delete album. It may contain purchased media.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    fetchAlbum();
  }, [fetchAlbum]);

  useEffect(() => {
    let intervalId: number;

    const pollAlbum = async () => {
      const updatedAlbum = await fetchAlbum();
      if (
        updatedAlbum &&
        !updatedAlbum.media.some((item) => !item.is_processed)
      ) {
        // If all media items are processed, stop polling
        clearInterval(intervalId);
      }
    };

    if (album && album.media.some((item) => !item.is_processed)) {
      // Start polling every 5 seconds if there's any unprocessed media
      intervalId = window.setInterval(pollAlbum, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [album, fetchAlbum]);

  const handleMediaUpdate = (updatedMedia: Media) => {
    setAlbum((prevAlbum) => {
      if (!prevAlbum || !prevAlbum.media) return null;
      return {
        ...prevAlbum,
        media: prevAlbum.media.map((item) =>
          item.id === updatedMedia.id ? updatedMedia : item
        ),
      };
    });
  };

  const handleMediaUploadComplete = (newMedias: Media[]) => {
    setAlbum((prevAlbum) => {
      if (!prevAlbum || !prevAlbum.media) return null;
      return {
        ...prevAlbum,
        media: [...prevAlbum.media, ...newMedias],
      };
    });
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (!album) {
    return <Box>Album not found</Box>;
  }

  return (
    <Box p={4}>
      <Box
        borderWidth="2px"
        borderRadius="xl"
        borderColor={"accent.600"}
        p={4}
        bg={"white"}
        dropShadow={"lg"}
        marginBottom={4}
      >
        <Box display="flex" flexDir={{ base: "column", md: "row" }} justifyContent="space-between" alignItems={{ base: "start", md: "center" }} marginBottom={4}>
          <VStack align="start" spacing={2}>
            <Heading size="md">{album.surf_break.name}</Heading>
            <Text>
              {album.start_datetime.toLocaleDateString()}{" "}
              {`${album.start_datetime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} - ${album.end_datetime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}`}
            </Text>
            <Text>{album.media.length} photos & videos</Text>
          </VStack>

          {isOwner && (
            <Button 
              variant="ghost" 
              colorScheme="red" 
              size="sm"
              leftIcon={<DeleteIcon />}
              onClick={() => setIsDeleting(true)}
              mt={{ base: 2, md: 0 }}
            >
              Delete Album
            </Button>
          )}
        </Box>

        {isOwner && (
          <MediaUploader
            albumId={album.id}
            onUploadComplete={handleMediaUploadComplete}
          />
        )}
      </Box>

      <MediaGrid
        media={album.media}
        isOwner={isOwner}
        onMediaUpdate={handleMediaUpdate}
      />

      <AlertDialog
        isOpen={isDeleting}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleting(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Album
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This will permanently delete the album and all its contents. This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleting(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAlbum} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default AlbumDetailsPage;
