import api from './api';
import { User } from '../types/user';

export const getPhotographerProfile = async (username: string): Promise<User> => {
  const response = await api.get(`/api/users/${username}/`);
  return response.data;
};

export const getCurrentUserProfile = async (): Promise<User> => {
  const response = await api.get('/api/me/');
  return response.data;
};

export const updateProfile = async (data: Partial<User>): Promise<User> => {
  const response = await api.patch('/api/me/', data);
  return response.data;
};
