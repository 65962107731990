import api from './api';
import { Album } from '../types/album';

interface CreateAlbumData {
  surf_break: string; // SurfBreak ID
  start_datetime: string;
  end_datetime: string;
  default_photo_price: number;
  default_video_price: number;
}
const convertAlbum = (albumData: any): Album => ({
  ...albumData,
  start_datetime: new Date(albumData.start_datetime),
    end_datetime: new Date(albumData.end_datetime),
});

export const createAlbum = async (data: CreateAlbumData): Promise<Album> => {
  const response = await api.post('/api/albums/', data);
  return convertAlbum(response.data);
};

export const getAlbums = async (): Promise<Album[]> => {
  const response = await api.get('/api/albums/');
  return response.data.map(convertAlbum);
};

export const getAlbum = async (albumId: string): Promise<Album> => {
  const response = await api.get(`/api/albums/${albumId}/`);
  return convertAlbum(response.data);
};

export const getAlbumsByPhotographer = async (username: string): Promise<Album[]> => {
  const response = await api.get(`/api/albums/?photographer=${username}`);
  return response.data.map(convertAlbum);
};

export const deleteAlbum = async (albumId: string): Promise<void> => {
  await api.delete(`/api/albums/${albumId}/`);
};