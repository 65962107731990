import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, Spinner } from "@chakra-ui/react";
import { getAlbum } from "../services/albums";
import { Album } from "../types/album";
import MediaGrid from "../components/MediaGrid";

const PublicAlbumDetailsPage: React.FC = () => {
  const { albumId } = useParams<{ albumId: string }>();
  const [album, setAlbum] = useState<Album | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAlbum = async () => {
      try {
        const albumData = await getAlbum(albumId!);
        setAlbum(albumData);
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAlbum();
  }, [albumId]);

  if (loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner />
      </Box>
    );
  }

  if (!album) {
    return (
      <Box textAlign="center" mt={10}>
        <Text>Album not found.</Text>
      </Box>
    );
  }

  return (
    <Box p={4}>
      <Heading>{album.surf_break.name}</Heading>
      <Text>{album.start_datetime.toLocaleDateString()}</Text>
      <Text mt={4} mb={4}>
        {album.media.length} photos & videos
      </Text>
      <MediaGrid media={album.media} onMediaUpdate={() => {}} />
    </Box>
  );
};

export default PublicAlbumDetailsPage;
